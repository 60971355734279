import React, { PureComponent, CSSProperties } from "react";
import "./Skeleton.css";

interface PropsInterface {
  count?: number;
  isAnimated?: boolean;
  height?: string;
  style?: CSSProperties;
}

export class Skeleton extends PureComponent<PropsInterface> {
  public render(): JSX.Element {
    const { count, height, isAnimated = false, style = {} } = this.props;
    const elementCount = count || 1;

    const styleRules = {
      ...style,
      ...{ height: height ? height : "auto" }
    };

    const elements = [...Array(elementCount)].map(
      (val: undefined, i: number): JSX.Element => {
        return (
          <div
            className={`skeleton_element ${
              isAnimated ? "skeleton_element--animated" : ""
            }`}
            key={`skeleton-${i}`}
            style={styleRules}
          />
        );
      }
    );

    return <div className="skeleton_wrapper">{elements}</div>;
  }
}
