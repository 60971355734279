import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { DrawerProps } from "antd-mobile/lib/drawer/PropsType";
import { Drawer, List, NavBar, SearchBar } from "antd-mobile";
import {
  setActiveSection,
  setActiveArticle,
  setViewMode
} from "actions/ConfigActions";
import { StateInterface } from "reducers/rootReducer";
import memoizeOne from "memoize-one";
import { importSectionArticlePreviews } from "actions/ArticleActions";
import {
  SECTIONS,
  MiniSectionInterface,
  getSectionByFuzzyName,
  sectionSearch
} from "data/sections";
import { EditionSectionInterface } from "actions/EditionActions";
import { SectionInterface } from "data/sections";
import { MenuChoicesTypes } from "variants/menuTypes";
import SearchResult from "components/SearchResults/SearchResults";
import { SectionNavigation } from "components/SectionNavigation/SectionNavigation";
import { getSearchResults } from "services/content/contentProxy";
import { ViewModes } from "reducers/configReducer";
import { navHistory } from "libs/NavHistory";
import "./SideDrawer.css";

const { Item } = List;

type SuggestedSections = { id: string; text: string }[];

interface PropsInterface extends MapStatePropsInterface, MapDispatchProps {
  isOpen: boolean;
  children: JSX.Element[];
}

interface LocalStateInterface {
  selectedId: undefined | string;
  searchOpen: boolean;
  searchValue: string;
  fetchingArticles: boolean;
  timeoutID: number;
  suggestedSections: SuggestedSections;
  articleResults: string[];
}

class SideDrawer extends PureComponent<PropsInterface, LocalStateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      selectedId: undefined,
      searchOpen: false,
      searchValue: "",
      timeoutID: 0,
      articleResults: [],
      suggestedSections: [],
      fetchingArticles: false
    };
  }

  private handleClose = (): void => {
    this.setState({
      searchOpen: false,
      searchValue: "",
      articleResults: [],
      fetchingArticles: false
    });

    history.back();
  };

  private generateSuggestedSections(results: string[]): SuggestedSections {
    return results.reduce((acc: SuggestedSections, c): SuggestedSections => {
      const topResult = getSectionByFuzzyName(c);

      return topResult
        ? [...acc, { id: topResult.id, text: topResult.text }]
        : acc;
    }, []);
  }

  private searchWebArticles = (): void => {
    const { importSectionArticlePreviews } = this.props;
    const { searchValue } = this.state;

    if (searchValue.length < 4) {
      this.setState({ fetchingArticles: false });
      return;
    }

    getSearchResults(searchValue)
      .then((searchResults): void => {
        const suggestedSections = this.generateSuggestedSections(
          searchResults.suggestedSections
        );

        this.setState({
          suggestedSections: [
            ...this.state.suggestedSections,
            ...suggestedSections
          ],
          articleResults: Object.keys(searchResults.results) || [],
          fetchingArticles: false
        });

        importSectionArticlePreviews(searchResults.results);
      })
      .catch((err): void => {
        console.error(err && err);
        this.setState({ fetchingArticles: false });
      });

    this.setState({ fetchingArticles: true });
  };

  private handleClusterClick = (id: string): void => {
    console.log("clicked", id);
    history.pushState(null, "", id);
  };

  private handleInput = (val: string): void => {
    const { timeoutID } = this.state;
    timeoutID && clearTimeout(timeoutID);

    const newTimeoutID = window.setTimeout(this.searchWebArticles, 1000);
    this.setState({
      searchValue: val,
      timeoutID: newTimeoutID,
      articleResults: [],
      suggestedSections: [],
      fetchingArticles: val.length > 3
    });
  };

  private handleCancel = (): void =>
    this.setState({
      searchOpen: false,
      searchValue: "",
      articleResults: [],
      fetchingArticles: false
    });

  private handleClear = (): void =>
    this.setState({
      searchValue: "",
      articleResults: [],
      fetchingArticles: false
    });

  private handleFocus = (): void => this.setState({ searchOpen: true });

  private header = (): JSX.Element => {
    const { searchOpen, searchValue } = this.state;

    return (
      <div>
        <NavBar
          className="navbar"
          leftContent={<button onClick={this.handleClose}>Back</button>}
        >
          <SearchBar
            cancelText="Cancel"
            className="sidebar__search"
            onCancel={this.handleCancel}
            onChange={this.handleInput}
            onClear={this.handleClear}
            onFocus={this.handleFocus}
            placeholder="Search for sections, articles and more"
            showCancelButton={false}
            value={searchValue}
          />
        </NavBar>
      </div>
    );
  };

  public handleSelectArticle = (id: string): void => {
    // const { setActiveArticle, setViewMode } = this.props;
    // setActiveArticle(id);
    // setViewMode(ViewModes.ARTICLE_SINGLE);
    history.pushState(null, "", `article/${id}`);
  };

  private buildNavList(): JSX.Element {
    const { sections, menuType } = this.props;
    const {
      selectedId,
      searchOpen,
      searchValue,
      fetchingArticles,
      suggestedSections,
      articleResults
    } = this.state;

    const sectionSearchResults = sectionSearch(searchValue) || [];
    const deDupedSearchResults = sectionSearchResults.reduce(
      (acc: SectionInterface[], r): SectionInterface[] =>
        acc.map((c): string => c.text).includes(r.text) ? acc : [...acc, r],
      []
    );

    const deDupedSearchResultsIds = deDupedSearchResults.map(
      (s): string => s.id
    );

    const duDupedSuggestedResults = suggestedSections.filter(
      (r): boolean => deDupedSearchResultsIds.includes(r.id) === false
    );

    const searchResults = [...deDupedSearchResults, ...duDupedSuggestedResults];

    return (
      <List
        className={`sidebar ${selectedId ? "sidebar--selected" : ""}`}
        renderHeader={this.header}
      >
        {searchOpen === false && (
          <div>
            <Item className="item-top" key="saved">
              Recently visited
            </Item>
            <SectionNavigation
              menuType={menuType}
              sections={sections}
              selectedId={selectedId}
              selectSection={this.handleClusterClick}
            />
          </div>
        )}

        {searchOpen && (
          <SearchResult
            articleIds={articleResults}
            fetchingArticles={fetchingArticles}
            searchResults={searchResults}
            selectArticle={this.handleSelectArticle}
            selectSection={this.handleClusterClick}
          />
        )}
      </List>
    );
  }

  public render(): JSX.Element {
    const { isOpen, children, drawerPosition } = this.props;
    const Sidebar = this.buildNavList();

    return (
      <Drawer
        transitions={false}
        className={`drawer ${isOpen ? "drawer--open" : ""}`}
        contentStyle={{
          overflow: "hidden"
        }}
        onOpenChange={this.handleClose}
        open={isOpen}
        position={drawerPosition}
        sidebar={Sidebar}
        sidebarStyle={{
          right: 0,
          left: 0
        }}
        style={{ minHeight: "100%" }}
      >
        {children}
      </Drawer>
    );
  }
}

function getChildSections(id: string): SectionInterface[] {
  return Object.values(SECTIONS).filter(
    (section): boolean => section.parent === id
  );
}

function getSections(sections: {
  [id: string]: EditionSectionInterface;
}): MiniSectionInterface[] {
  return Object.values(sections)
    .filter((section): boolean => {
      return SECTIONS[section.id].level === 0;
    })
    .map(
      (section): MiniSectionInterface => ({
        id: section.id,
        text: SECTIONS[section.id].text
      })
    );
}

const memGetSections = memoizeOne(
  getSections,
  ([newSections], [oldSections]): boolean =>
    Object.keys(newSections).join() === Object.keys(oldSections).join()
);

interface MapStatePropsInterface {
  sections: MiniSectionInterface[];
  allSections: SectionInterface[];
  drawerPosition: DrawerProps["position"];
  menuType: MenuChoicesTypes;
}

const mapStateToProps = (state: StateInterface): MapStatePropsInterface => {
  const { activeEdition } = state.config;
  const { menuTypes } = state.variants;
  const drawerPosition = state.variants["drawerPosition"].selected;
  const sections = memGetSections(state.editions[activeEdition].sections);

  const allSections = sections.reduce(
    (acc: SectionInterface[], s): SectionInterface[] => {
      acc = [...acc, ...getChildSections(s.id), SECTIONS[s.id]];
      return acc;
    },
    []
  );

  return {
    sections,
    allSections,
    drawerPosition: drawerPosition,
    menuType: menuTypes.selected
  };
};

interface MapDispatchProps {
  setActiveSection: typeof setActiveSection;
  setActiveArticle: typeof setActiveArticle;
  setViewMode: typeof setViewMode;
  importSectionArticlePreviews: typeof importSectionArticlePreviews;
}

export default connect(
  mapStateToProps,
  {
    setActiveSection,
    setActiveArticle,
    setViewMode,
    importSectionArticlePreviews
  }
)(SideDrawer);
