/* eslint @typescript-eslint/camelcase: "off" */

import { SECTIONS } from "data/sections";

export const editionState = {
  LIVE_EDITION: {
    edition_id: "LIVE_EDITION",
    published: "2019-05-28T14:05:28.704Z",
    last_updated: "2019-05-28T14:05:28.704Z",
    downloaded: false,
    downloading: false,
    download_progress: 0,
    sections: Object.values(SECTIONS).reduce(
      (acc, s): {} => ({
        ...acc,
        [s.id]: {
          loaded: false,
          fetching: false,
          id: s.id,
          articles: [],
          packages: []
        }
      }),
      {}
    ),
    packages: {},
    type: "LIVE"
  },
  "2019-05-27": {
    edition_id: "2019-05-27",
    published: "2019-05-27T14:05:28.704Z",
    last_updated: "2019-05-27T14:05:28.704Z",
    downloaded: false,
    downloading: false,
    download_progress: 0,
    sections: {},
    packages: {
      front_page_0001: {
        id: "front_page_0001",
        title: "package title",
        url: null,
        articles: []
      },
      front_page_0002: {
        id: "front_page_0002",
        title: "package title",
        url: null,
        articles: []
      }
    },
    type: "DAILY"
  },
  "2019-05-26": {
    edition_id: "2019-05-26",
    published: "2019-05-26T14:05:28.704Z",
    last_updated: "2019-05-26T14:05:28.704Z",
    downloaded: false,
    downloading: false,
    download_progress: 0,
    sections: {},
    packages: {
      front_page_0001: {
        id: "front_page_0001",
        title: "package title",
        url: null,
        articles: []
      },
      front_page_0002: {
        id: "front_page_0002",
        title: "package title",
        url: null,
        articles: []
      }
    },
    type: "DAILY"
  },
  "2019-05-25": {
    edition_id: "2019-05-25",
    published: "2019-05-25T14:05:28.704Z",
    last_updated: "2019-05-25T14:05:28.704Z",
    downloaded: false,
    downloading: false,
    download_progress: 0,
    sections: {},
    packages: {
      front_page_0001: {
        id: "front_page_0001",
        title: "package title",
        url: null,
        articles: []
      },
      front_page_0002: {
        id: "front_page_0002",
        title: "package title",
        url: null,
        articles: []
      }
    },
    type: "DAILY"
  },
  "2019-05-24": {
    edition_id: "2019-05-24",
    published: "2019-05-24T14:05:28.704Z",
    last_updated: "2019-05-24T14:05:28.704Z",
    downloaded: false,
    downloading: false,
    download_progress: 0,
    sections: {},
    packages: {
      front_page_0001: {
        id: "front_page_0001",
        title: "package title",
        url: null,
        articles: []
      },
      front_page_0002: {
        id: "front_page_0002",
        title: "package title",
        url: null,
        articles: []
      }
    },
    type: "DAILY"
  },
  "2019-05-23": {
    edition_id: "2019-05-23",
    published: "2019-05-23T14:05:28.704Z",
    last_updated: "2019-05-23T14:05:28.704Z",
    downloaded: false,
    downloading: false,
    download_progress: 0,
    sections: {},
    packages: {
      front_page_0001: {
        id: "front_page_0001",
        title: "package title",
        url: null,
        articles: []
      },
      front_page_0002: {
        id: "front_page_0002",
        title: "package title",
        url: null,
        articles: []
      }
    },
    articles: ["xxx", "xxx", "xxx"],
    type: "DAILY"
  }
};

export const sectionsState = {
  "top-stories": {
    section_id: "top-stories",
    display_name: "Top Stories",
    colour: "#F00"
  },
  news: {
    section_id: "news",
    display_name: "Front page",
    colour: "#F00"
  },
  politics: {
    section_id: "politics",
    display_name: "Politics",
    colour: "#045"
  },
  sport: {
    section_id: "sport",
    display_name: "Sport",
    colour: "#0FF"
  },
  PRINT_ONLY: {
    section_id: "PRINT_ONLY",
    display_name: "Print only section",
    colour: "#F0F"
  }
};
