import React, { ChangeEventHandler } from "react";

interface PropsInterface {
  handleChange: ChangeEventHandler;
  id: string;
  options: (string | number | undefined)[];
  selected: number | string | undefined;
}

export function SelectElement(props: PropsInterface): JSX.Element {
  const { handleChange, options, selected, id } = props;

  return (
    <select name={id} onChange={handleChange} value={selected}>
      {options.map(
        (v): JSX.Element => (
          <option key={v} value={v}>
            {v}
          </option>
        )
      )}
    </select>
  );
}
